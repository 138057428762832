import React from "react"
import { Grid, Box, Text, Image } from "theme-ui"

import commonersImage from "../../images/spots/commoners.png"

export default function GeneralRules() {
  return (
    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="label">General Rules</Text>
        <Text as="p" variant="display">Be good to each other</Text>
      
        <Text as="p" variant="bodyLarge">Quest works best when people treat each other and the conversation with mutual respect. Which is to say: be nice at the table, even if your character is mean sometimes.</Text>
        <Text as="p" variant="bodyLarge">The game doesn’t work when people are angry, rude, or uninterested in what’s going on. In the real world outside of the game, everyone deserves to be treated with respect. When you decide to spend your time sharing a story together, you should do your best to make sure everyone has a good time.</Text>
        
        <Image src={commonersImage} alt="Commoners" sx={{ my: 4, width: "100%" }} />
       
        <Text as="h3" variant="title03">Respect Personal Boundaries</Text>
        <Text as="p" variant="body">Don’t say things to people either out of character or in roleplaying that make them uncomfortable in the real world. Don’t introduce extreme concepts into the game, like torture or sexual violence, unless everyone agrees beforehand that they are acceptable to use in your story. (If you have any doubts about subject matter, it’s probably best to leave it out.)</Text>
        
        <Text as="h3" variant="title03" sx={{ mt: 4 }}>Let Your Friends Talk</Text>
        <Text as="p" variant="body">Everyone should get a chance to speak without being interrupted. Make sure you are not dominating all of the conversation or action. Share the story.</Text>
        
        <Text as="h3" variant="title03" sx={{ mt: 4 }}>Only Play Your Character</Text>
        <Text as="p" variant="body">Don’t tell other people what to do, even if you think you have the best idea. You can’t win or lose a game of Quest in the traditional sense. People are often supposed to fail or do silly things, especially if they are roleplaying.</Text>
        
        <Text as="h3" variant="title03" sx={{ mt: 4 }}>Be Nice to Your Guide</Text>
        <Text as="p" variant="body">It takes a lot of work to prepare for the game and keep it running over time. Show respect for your Guide by paying attention when they are helping tell the story. It can feel hurtful when people are distracted.</Text>
        <Text as="p" variant="body">Since the Guide is bringing lots of work to the table, it’s a nice gesture for the players to take turns providing things like snacks and drinks for the group.</Text>
        
        <Text as="h3" variant="title03" sx={{ mt: 4 }}>Silence Your Phone</Text>
        <Text as="p" variant="body">Please.</Text>
        
        <Text as="h3" variant="title03" sx={{ mt: 4 }}>Ask for Consent</Text>
        <Text as="p" variant="body">It is against the rules for player characters to attack other party members, steal from them, restrain them, or force them to do anything against their wishes. If you want to roleplay an adversarial situation, go out of character to ask the other player if it’s okay.</Text>
        
        <Text as="h3" variant="title03" sx={{ mt: 4 }}>Don’t be Evil, Unless…</Text>
        <Text as="p" variant="body">The players’ objectives should be compatible because they are allies. The game can quickly break if one player is overtly evil and wants to go around murdering everyone they meet.</Text>
        <Text as="p" variant="body">That doesn’t mean you can’t break the law or do bad things, and oftentimes, doing the wrong thing can be good for the story. But don’t be evil unless the entire group agrees ahead of time. The story belongs to the entire group.</Text>
        
        <Text as="h3" variant="title03" sx={{ mt: 4 }}>Be Mindful of Secrets</Text>
        <Text as="p" variant="body">Sometimes you will need to keep secrets from your character. For example, let’s say the party splits up, and the Guide describes a scene your character is not in. You will hear what happens, but your character is not supposed to know.</Text>
        <Text as="p" variant="body">Try not to use information your character doesn’t have to roleplay or make decisions. Wait for another player to share it, if they want to.</Text>
        
        <Text as="h3" variant="title03" sx={{ mt: 4 }}>Speak Clearly</Text>
        <Text as="p" variant="body">You will use two voices: your out-of-character voice (you in the real world) and your in-character voice (roleplaying). Make sure it is always clear which voice you are using to prevent confusion at the table.</Text>
        
        <Text as="h3" variant="title03" sx={{ mt: 4 }}>Flip a Coin</Text>
        <Text as="p" variant="body">If your party can’t agree on a course of action and everyone feels stuck, considering going out of character to flip a coin between two alternatives. Then, respect the result, and move on.</Text>
        
        <Text as="h3" variant="title03" sx={{ mt: 4 }}>Be a Fair Guide</Text>
        <Text as="p" variant="body">The Guide’s job is to narrate the story, play characters and monsters in the world, and create consequences for all of the players’ actions.</Text>
        <Text as="p" variant="body">All of that power comes with a lot of responsibility. Because the Guide’s judgment is so critical to the enjoyment of the game, they have a duty to act wisely and fairly. It’s no fun to have a Guide who constantly punishes the players in cruel ways, singles out one player all the time, plays favorites, or uses rules inconsistently.</Text>
        <Text as="p" variant="body">Even though the Guide’s word is final, they are expected to make consistent and fair decisions about the game.</Text>
        
        <Text as="h3" variant="title03" sx={{ mt: 4 }}>Help Everyone Have Fun</Text>
        <Text as="p" variant="body">The stakes in your story will often be high, but sometimes they will also feel high in the real world.</Text>
        <Text as="p" variant="body">You might feel upset about what another player says or does. Maybe you feel that the rules were used unfairly, or maybe you’re just having a bad day.</Text>
        <Text as="p" variant="body">That’s okay, but remember that this experience is about having fun. If you’re not having fun, tell your friends why. And be sure to listen to your friends if they’re upset. If tensions are too high, everyone should take a break and come back to the game later.</Text>
        <Text as="p" variant="body">Always remember: Quest is not about willing or losing: it’s about spending quality time with your friends.</Text>
      </Box>
    </Grid>
  )
}

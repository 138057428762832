import React from "react"
import { Grid, Box, Text } from "theme-ui"
import ChatBubble from "../adventures/ChatBubble"

export default function RollingTheDie() {
  return (
    <> 
      <Grid variant="textWrap">
        <Box>
          <Text as="h2" variant="label" data-sal="fade-in" data-sal-duration="500">Rolling the Die</Text>
          <Text as="p" variant="display" data-sal="slide-up" data-sal-duration="500">Roll the die to test your luck</Text>
          <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You roll the die in Quest to let fate decide what happens next. The Guide will ask you to roll when you try to do something risky, or when a bit of chance makes things fun.</Text>
          <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">After you roll, look at the number on top of the die. Then compare the result to the list of outcomes below. The Guide decides what the consequences are.</Text>
          <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You may find fortune! But if things go badly, you will suffer a setback. You might lose your belongings, accidentally harm a friend, or face a painful choice.</Text>
        </Box>
      </Grid>
      
      <Grid variant="base" gap={4} columns={[1, 1, 5]} sx={{ mt: 6 }}>
        <Box data-sal="slide-up" data-sal-delay="100" data-sal-duration="500">
          <Text as="p" variant="display" sx={{ mb: 1 }}>20</Text>
          <Text as="p" variant="title02">Triumph</Text>
          <Text as="p" variant="body">This is an exciting moment. You automatically succeed at what you were trying to do, and you may even find added fortune. If you’re dealing damage, double it.</Text>
        </Box>

        <Box data-sal="slide-up" data-sal-delay="200" data-sal-duration="500">
          <Text as="p" variant="display" sx={{ mb: 1 }}>11-19</Text>
          <Text as="p" variant="title02">Success</Text>
          <Text as="p" variant="body">You accomplish what you were trying to do without any compromises. If you’re dealing damage, you deal the standard amount.</Text>
        </Box>

        <Box data-sal="slide-up" data-sal-delay="300" data-sal-duration="500">
          <Text as="p" variant="display" sx={{ mb: 1 }}>6-10</Text>
          <Text as="p" variant="title02">Tough Choice</Text>
          <Text as="p" variant="body">You succeed in your action, but there’s a cost. The Guide will give you a choice between two setbacks.</Text>
        </Box>

        <Box data-sal="slide-up" data-sal-delay="400" data-sal-duration="500">
          <Text as="p" variant="display" sx={{ mb: 1 }}>2-5</Text>
          <Text as="p" variant="title02">Failure</Text>
          <Text as="p" variant="body">You fail your intended action and face a setback of the Guide’s choice. You might lose equipment, take damage from an enemy counterattack, or face some other misfortune.</Text>
        </Box>

        <Box data-sal="slide-up" data-sal-delay="500" data-sal-duration="500">
          <Text as="p" variant="display" sx={{ mb: 1 }}>1</Text>
          <Text as="p" variant="title02">Catastrophe</Text>
          <Text as="p" variant="body">Oh no. You automatically fail, and you may suffer a severe setback.</Text>
        </Box>
      </Grid>

      <Grid variant="textWrap">
        <Grid sx={{ mt: 6 }}>
          <ChatBubble
            quote="Okay, roll the die to see if you strike the monster."
            cite="Guide" />
          
          <ChatBubble
            quote="YES. That’s a 20."
            cite="Felix" />
          
          <ChatBubble
            quote="yessssss"
            cite="Harper" />

          <ChatBubble
            quote="You smack the monster right in the face with the side of your sword. It spits out several fangs. It’s so surprised by your bravery that it turns and runs away deeper into the cave!"
            cite="Guide" />
        </Grid>
      </Grid>
    </>
  )
}

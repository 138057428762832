import React from "react"
import { Grid, Box, Text } from "theme-ui"
import ChatBubble from "../adventures/ChatBubble"

export default function HowToDoThings() {
  return (
    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="label" data-sal="fade-in" data-sal-duration="500">How to do Things</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">Once you know what you want to do in the story, doing it is simple. </Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">All you have to do is say what your character does. If you want to open a door, say that you open it. If you want to talk to another character, start talking to them.</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">See a good dog? Say “I pet the dog.”</Text>
        
        <Grid sx={{ my: 6 }}>
          <ChatBubble
            quote="Who’s a good boy?"
            cite="Harper" /> 
        </Grid>
        
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You can play your role by speaking both as your character’s narrator and actor.</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You can narrate by telling the group what your character is doing. </Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You might describe how your character moves across a room, or what their facial expressions look like when they react to something. Or, you might describe how something makes them feel.</Text>

        <Grid sx={{ my: 6 }}>
          <ChatBubble
            quote="Felix feels sad that he was picked last for the mission. He walks over to the corner with his head down and pouts."
            cite="Felix" /> 
        </Grid>

        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You can also step into your character’s shoes and speak their lines.</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">When you speak as your character, it’s like being their actor in a movie. You can talk to your companions this way, or any other character you encounter in the game.</Text>

        <Grid sx={{ my: 6 }}>
          <ChatBubble
            quote="Hey Felix, listen to me. You’re in this party for a reason. I need your help if we’re going to make it."
            cite="Harper" /> 

          <ChatBubble
            quote="Thanks, Harper. It means a lot that you believe in me."
            cite="Felix" /> 
        </Grid>
      </Box>
    </Grid>
  )
}

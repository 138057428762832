import React from "react"
import { Grid, Box, Text } from "theme-ui"
import ChatBubble from "../adventures/ChatBubble"

export default function WhatToDo() {
  return (
    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="label" data-sal="fade-in" data-sal-duration="500">What to do</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">This game is about imagining scenes in a fantasy world, then deciding how your character responds to them. You can do anything in Quest, but here are some things you might do often.</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You can investigate the scene by asking questions to the Guide:</Text>
        
        <Grid sx={{ my: 6 }}>
          <ChatBubble
            quote="How many fangs do I see in the monster’s mouth?"
            cite="Harper" />
          
          <ChatBubble
            quote="Harper can’t count that high."
            cite="Guide" />
          
          <ChatBubble
            quote="Uh ohhhhh."
            cite="Harper" />
        </Grid>
        
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">Describe how your character feels, or say what they’re thinking:</Text>

        <Grid sx={{ my: 6 }}>
          <ChatBubble
            quote="I don’t think we’re prepared for that many fangs. Harper thinks we should retreat."
            cite="Harper" />
        </Grid>

        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">Talk to your fellow party members, or any NPCs you meet:</Text>

        <Grid sx={{ my: 6 }}>
          <ChatBubble
            quote="This is my moment, Harp. I’m not throwing away my shot."
            cite="Felix" /> 
        </Grid>
        
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">Make attacks on your adversaries with your weapons:</Text>

        <Grid sx={{ my: 6 }}>
          <ChatBubble
            quote="Okay, I’m going to take out my sword and charge in."
            cite="Felix" />
          
          <ChatBubble
            quote="Nice! The monster’s eyes open wide. It wasn’t expecting you to do that."
            cite="Guide" />
        </Grid>

        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">Use your special abilities.</Text>

        <Grid sx={{ my: 6 }}>
          <ChatBubble
            quote="Harper is so proud of Felix. She’s going to cast her Fear spell on the monster to try to make it run away."
            cite="Harper" /> 
        </Grid>
      </Box>
    </Grid>
  )
}

import React from "react"
import { Grid, Box, Text } from "theme-ui"

export default function TheBasics() {
  return (
    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="label" data-sal="fade-in" data-sal-duration="500" data-sal-delay="300">The Basics</Text>
        <Text as="p" variant="bodyLarge" sx={{ mb: 5 }} data-sal="slide-up" data-sal-duration="500">Quest works best with 4-6 players. One person plays as The Guide, which is the story’s narrator. Everyone else will create one of the story’s main characters, and then play as the character they created. These player characters are allies in an adventuring group called the party.</Text>
        
        <Grid columns={[1, 2]} gap={[3, 4, 5]}>
          <Box>
            <Text as="p" variant="bodyLarge" sx={{ mb: 1 }} data-sal="slide-up" data-sal-duration="500">Stuff the Guide does:</Text>
            <Box as="ul" sx={{ mb: 3 }}>
              <Text as="li" variant="bodyLarge" sx={{ m: 0 }} data-sal="slide-up" data-sal-duration="300">Imagine the people, places, and things the party encounters</Text>
              <Text as="li" variant="bodyLarge" sx={{ m: 0 }} data-sal="slide-up" data-sal-duration="300">Describe what the players sense, and what happens</Text>
              <Text as="li" variant="bodyLarge" sx={{ m: 0 }} data-sal="slide-up" data-sal-duration="300">Plays the part of the non-player characters, like townspeople, monsters, and bosses</Text>
              <Text as="li" variant="bodyLarge" sx={{ m: 0 }} data-sal="slide-up" data-sal-duration="300">Imagines the consequences for the party’s actions</Text>
            </Box>
          </Box>
          
          <Box>
            <Text as="p" variant="bodyLarge" sx={{ mb: 1 }} data-sal="slide-up" data-sal-duration="500">Stuff the party does:</Text>
            <Box as="ul">
              <Text as="li" variant="bodyLarge" sx={{ m: 0 }} data-sal="slide-up" data-sal-duration="300">Imagine what their characters think, feel, and do</Text>
              <Text as="li" variant="bodyLarge" sx={{ m: 0 }} data-sal="slide-up" data-sal-duration="300">Play their roles and explore their characters’ personalities</Text>
              <Text as="li" variant="bodyLarge" sx={{ m: 0 }} data-sal="slide-up" data-sal-duration="300">Work together to solve problems and advance the story</Text>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  )
}

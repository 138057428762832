import React from "react"
import { Grid, Box, Text } from "theme-ui"

import ChatBubble from "../adventures/ChatBubble"

export default function HowtoPlay() {
  return (
    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="label" data-sal="fade-in" data-sal-duration="500">How to Play</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You play the game by having a conversation with your friends that begins like this:</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">First, the Guide describes your scene to help you imagine the world and what is happening. Next, you say what your character does. You can do anything you want, like talk to other characters, attack, or use one of your special abilities. Then, the Guide imagines what happens next and describes the scene again.</Text>
        
        <Grid sx={{ mt: 6 }}>
          <ChatBubble
            quote="You feel a light breeze on your skin as you walk through the forest. The trees begin to rustle gently, but then you feel the earth rumble beneath you. Birds jump from the canopy and flee the forest. Suddenly, a giant bear bursts through the trees! As she looks down at you, you notice something strange. She’s wearing armor and has an axe at her side. What do you do?"
            cite="The Guide" />

          <ChatBubble
            quote="Oh wow. I’m startled and reach for my sword, but when I see the bear is wearing clothes, I calm down a little bit. I want to talk to her. I look through my bag and pull out a granola bar and give it to her."
            cite="Harper, the party’s Magician" />

          <ChatBubble
            quote='The bear sniffs the air in your direction and smiles. She takes the granola bar from you and munches on it. After licking her lips, she rolls backward and sits upright. “My name is Claw,” she says. “I am the protector of this forest.”'
            cite="The Guide" />

          <ChatBubble
            quote="Um, yes, Claw rules. I want to be her friend."
            cite="Harper, the party’s Magician" />
        </Grid>
      </Box>
    </Grid>
  )
}

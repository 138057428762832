import React from "react"
import { Box, Grid, Text, Divider } from "theme-ui"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import LinkCard from "../../components/LinkCard"
import FullBleedImage from "../../components/FullBleedImage"

import TheGame from "../../components/rules/TheGame"
import TheBasics from "../../components/rules/TheBasics"
import StuffYouNeed from "../../components/rules/StuffYouNeed"
import HowToPlay from "../../components/rules/HowToPlay"
import Scenes from "../../components/rules/Scenes"
import HowToDoThings from "../../components/rules/HowToDoThings"
import WhatToDo from "../../components/rules/WhatToDo"
import ActionScenes from "../../components/rules/ActionScenes"
import Distances from "../../components/rules/Distances"
import RollingTheDie from "../../components/rules/RollingTheDie"
import DeadlyScenes from "../../components/rules/DeadlyScenes"
import Abilities from "../../components/rules/Abilities"
import TradeAndInventory from "../../components/rules/TradeAndInventory"
import GeneralRules from "../../components/rules/GeneralRules"
import WhatsNext from "../../components/rules/WhatsNext"

import spread02Image from "../../images/full-illustrations/spread-02.jpg"

const RulesPage = () => (
  <Layout>
    <SEO title="Rules" />

    <Breadcrumbs title="Rules" headline="You are an adventurer in a world of magic and danger" />
    
    <Box sx={{ backgroundImage: theme => `linear-gradient(180deg, white 0%, ${theme.colors.secondary10} 33%, ${theme.colors.primary10} 66%, white 90%)` }}>
      <TheGame />
      
      <Grid variant="base" columns={[1, 2, "1fr 2fr"]} sx={{ bg: "grayXLight", py: 5, my: [5, 6], alignItems: "center" }}>
        <Box>
          <Text as="h3" variant="title03">Listen to this page</Text>
          <Text as="p" variant="body">Join our narrator, The Guide, and two adventurers as they explore the first chapter of our Game Book.</Text>
        </Box>
        
        <Box sx={{ width: "100%", maxWidth: 768, justifySelf: "end" }}>
          <iframe title="Rules" src="https://anchor.fm/tc-sottek/embed/episodes/Learn-to-play-Quest-en9n0f" height="102px" width="100%" frameborder="0" scrolling="no"></iframe>
        </Box>
      </Grid>
      
      <FullBleedImage src={spread02Image} />
      
      <TheBasics />

      <Box sx={{ height: "75vh" }} />

      <StuffYouNeed />

      <Box sx={{ height: "75vh" }} />

      <HowToPlay />

      <Box sx={{ height: "75vh" }} />

      <Scenes />

      <Box sx={{ height: "75vh" }} />

      <HowToDoThings />

      <Box sx={{ height: "75vh" }} />

      <WhatToDo />

      <Box sx={{ height: "75vh" }} />

      <ActionScenes />

      <Box sx={{ height: "75vh" }} />

      <Distances />

      <Box sx={{ height: "75vh" }} />

      <RollingTheDie />

      <Box sx={{ height: "75vh" }} />

      <DeadlyScenes />

      <Box sx={{ height: "75vh" }} />

      <Abilities />

      <Box sx={{ height: "75vh" }} />

      <TradeAndInventory />

      <Box sx={{ height: "75vh" }} />

      <GeneralRules />

      <Box sx={{ height: "75vh" }} />

      <WhatsNext />

      <Grid variant="base">
        <Divider />
      </Grid>

    </Box>
    
    <Grid variant="base" columns={[1, 2]} sx={{ gridGap: 6 }}>
      <LinkCard title="Roles" url="/roles" buttonLabel="See the Roles" />
      <LinkCard title="Store" url="/store" buttonLabel="Buy Quest" />
    </Grid>
  </Layout>
)

export default RulesPage
import React from "react"
import { Grid, Box, Text } from "theme-ui"
import ChatBubble from "../adventures/ChatBubble"

export default function WhatsNext() {
  return (
    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="label" data-sal="fade-in" data-sal-duration="500">What's next?</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">If you want to play Quest, it’s time to pick up the Game Book and create your character.</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">Who will you become?</Text>
        
        <Grid sx={{ my: 6 }}>
          <ChatBubble
            quote="You hear birds chirping as you wake up in the morning. A few glowing coals remain in the campfire."
            cite="Guide" />
          
          <ChatBubble
            quote="I pour water on the campfire. It’s what our bear friend Claw told us to do!"
            cite="Harper" />
          
          <ChatBubble
            quote="I hand Harper her gear and take out my map. It’s a new day and we’ve got a long way to go together."
            cite="Felix" />
        </Grid>
      </Box>
    </Grid>
  )
}

import React from "react"
import { Grid, Box, Text, Image } from "theme-ui"

import distancesAImage from "../../images/spots/distances-a.png"
import distancesBImage from "../../images/spots/distances-b.png"
import distancesCImage from "../../images/spots/distances-c.png"
import distancesDImage from "../../images/spots/distances-d.png"

export default function Distances() {
  return (
    <Grid variant="base" sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", top: 4, left: [4, 6], maxWidth: ["80%", "40%"] }}>
        <Text as="h2" variant="label" data-sal="fade-in" data-sal-duration="500">Distances</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">Picture this: it’s your turn, and you see a mischievous monster. So you ask the Guide if it’s possible for you to hit it.</Text>
      </Box>

      <Grid gap={6} columns={[1, 4]} sx={{ p: 0 }}>
        <Box sx={{ mt: 8 }} data-sal="slide-up" data-sal-duration="500">
          <Image src={distancesAImage} alt="In Reach" sx={{ mb: 4, width: "100%" }} />
          <Text as="h3" variant="title02">In Reach</Text>
          <Text as="p" variant="bodyLarge">If the monster is in reach, you can touch or hit it from where you stand.</Text>
        </Box>

        <Box sx={{ mt: 6 }} data-sal="slide-up" data-sal-duration="500">
          <Image src={distancesBImage} alt="Nearby" sx={{ mb: 4, width: "100%" }} />
          <Text as="h3" variant="title02">Nearby</Text>
          <Text as="p" variant="bodyLarge">If the monster is nearby, you can move to be within reach of it during your turn.</Text>
        </Box>

        <Box sx={{ mt: 4 }} data-sal="slide-up" data-sal-duration="500">
          <Image src={distancesCImage} alt="In Range" sx={{ mb: 4, width: "100%" }} />
          <Text as="h3" variant="title02">In Range</Text>
          <Text as="p" variant="bodyLarge">If the monster is in range, you can hit it with a ranged attack this turn.</Text>
        </Box>

        <Box data-sal="fade-in" data-slide-up="500">
          <Image src={distancesDImage} alt="Too Far" sx={{ mb: 4, width: "100%" }} />
          <Text as="h3" variant="title02">Too Far</Text>
          <Text as="p" variant="bodyLarge">If the monster is too far, it’s beyond your range this turn. You’ll have to get closer.</Text>
        </Box>
      </Grid>
    </Grid>
  )
}

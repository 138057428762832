import React from "react"
import { Grid, Box, Text } from "theme-ui"

export default function StuffYouNeed() {
  return (
    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="label" data-sal="fade-in" data-sal-duration="500">Stuff You Need</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">Each player needs the Quest Game Book, a few hours of free time, a place to meet, a 20-sided die, and something for taking notes. You’ll also need the Quest Game Book, a character profile, a character worksheet, and an open mind.</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">It’s also nice to have a quiet room and some snacks and drinks.</Text>
      </Box>
    </Grid>
  )
}

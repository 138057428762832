import React from "react"
import { Grid, Box, Text, Image } from "theme-ui"

import basicGoodsImage from "../../images/spots/basic-goods.png"

export default function TradeAndInventory() {
  return (
    <>
      <Grid variant="textWrap">
        <Box>
          <Text as="h2" variant="label" data-sal="fade-in" data-sal-duration="500">Trade & Inventory</Text>
          <Text as="p" variant="display" data-sal="slide-up" data-sal-duration="500">Receive what you need, trade for things you want</Text>
        </Box>
      </Grid>

      <Grid variant="base" columns={[1, 2]} gap={[4, 6, 8]} sx={{ alignItems: "end" }}>
        <Box>
          <Text as="p" variant="bodyLarge" sx={{ width: ["100%", "150%"] }} data-sal="slide-up" data-sal-duration="500">Quest doesn’t use money. You can meet your basic needs in Quest without having to pay for them. This includes things like food and drink, a modest dwelling, and inexpensive items, like the things that stock the shelves of a convenience store. But if you want something valuable, like a shiny sword from a merchant or a room in a fancy hotel, you might have to trade one of your own valuable possessions for it.</Text>
          <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You can use your character worksheet to track your personal inventory. Your character can only personally carry a total of 12 things, and The Guide will decide what counts against this limit.</Text>
        </Box>
        
        <Image src={basicGoodsImage} alt="Basic goods" sx={{ my: 4, width: "100%" }} />
      </Grid>
    </>
  )
}

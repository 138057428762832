import React from "react"
import { Grid, Box, Text } from "theme-ui"

import ChatBubble from "../adventures/ChatBubble"

export default function Scenes() {
  return (
    <Grid variant="textWrap">
      <Box>
        <Text as="h2" variant="label" data-sal="fade-in" data-sal-duration="500" data-sal-delay>Scenes</Text>
        <Text as="p" variant="display" data-sal="slide-up" data-sal-duration="500" data-sal-delay>Explore each scene for clues</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500" data-sal-delay>You might find yourself in a bustling bazaar, a castle in the clouds, or a dark dungeon. Use what you sense in each scene to help you decide what to do. If there’s grease on the ground, you might slip on it. If a monster is three times your size, it might be able to pick you up. If the sky is dark, a storm may be coming.</Text>
        
        <Grid sx={{ my: 6 }}>
          <ChatBubble
            quote="What does it smell like in here?"
            cite="Felix, the party’s Fighter" /> 

          <ChatBubble
            quote="It smells like a thousand blooming flowers on the first day of spring."
            cite="The Guide" /> 
        </Grid>
        
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">Always think about the details the Guide gives you, and ask for more if you need it. You can start by asking what you sense, like “What does it smell like in here?” Or, you can ask something more specific, like “Do I see anything I can set on fire?”</Text>

        <Grid sx={{ my: 6 }}>
          <ChatBubble
            quote="Ooh… do I see anything I can set on fire?"
            cite="Harper" /> 

          <ChatBubble
            quote="As a matter of fact, there’s a giant cloth banner hanging behind the throne that goes from the floor to the ceiling."
            cite="The Guide" /> 
        </Grid>
      </Box>
    </Grid>
  )
}

import React from "react"
import { Grid, Box, Text, Image } from "theme-ui"
import ChatBubble from "../adventures/ChatBubble"

import battlegroundImage from "../../images/spots/battleground.png"

export default function DeadlyScenes() {
  return (
    <>
      <Grid variant="textWrap">
        <Box>
          <Text as="h2" variant="label" data-sal="fade-in" data-sal-duration="500">Deadly Scenes</Text>
          <Text as="p" variant="display" data-sal="slide-up" data-sal-duration="500">Don’t run out of hit points</Text>
          <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">Your character’s mortality is measured by a number called hit points, or HP. It’s simple: you lose hit points when you are harmed, like if you get stuck by a sword. We also call this damage.</Text>
          <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You lose hit points when you are damaged by weapons, spells, and other things that can cause harm. For example, you can lose 2 HP from being hit by a sword, while standing in a dragon’s fiery breath might damage you for 6 HP. You begin with 10 HP. This is the maximum amount of HP you can have.</Text>
        </Box>
      </Grid>
      
      <Grid variant="base">
        <Image src={battlegroundImage} sx={{ my: 5, maxWidth: "80%", mx: "auto" }} />
      </Grid>

      <Grid variant="textWrap">
        <Box>
          <Grid sx={{ my: 6 }}>
            <ChatBubble
              quote="You hear a loud rumbling sound and look behind you. A giant boulder is rolling through the cave toward you! Felix, roll the die to see if you can dodge it."
              cite="Guide" />
            
            <ChatBubble
              quote="Oh no. I rolled a 2. That’s a failure."
              cite="Felix" />
            
            <ChatBubble
              quote="Ouch. Yeah, you try to dive out of the way into a nook in the wall but you’re just a moment too late, and the boulder knocks you aside. You take 3 damage."
              cite="Guide" />
          </Grid>
          
          <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You can regain up to half of your total hit points by resting for a short time with no enemies nearby, and you can recover all of your hit points by completing a full night’s rest.</Text>
          <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You can’t be seriously hurt until you run out of hit points. You can’t be seriously hurt until you run out of hit points. But when you drop to 0 HP, you are at death’s door. You can’t go below 0 HP, but you will remain there until you regroup, rest, or heal. If you are hit while you are already at 0 HP, roll the die. If the result is equal to or less than the hit, your character dies. For example, if a sword hits you for 2 HP while at death’s door, you are killed if you roll a 1 or a 2.</Text>
        </Box>
      </Grid>
    </>
  )
}

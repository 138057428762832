import React from "react"
import { Link } from "gatsby"
import { Grid, Box, Text, Image } from "theme-ui"

import forbiddenTreasureImage from "../../images/spots/forbidden-treasure.png"

export default function Abilities() {
  return (
    <Grid variant="base" columns={[1, 1, 2]} gap={[4, 6, 8]} sx={{ alignItems: "end" }}>
      <Box>
        <Text as="h2" variant="label" data-sal="fade-in" data-sal-duration="500">Abilities</Text>
        <Text as="p" variant="display" data-sal="slide-up" data-sal-duration="500">Find your power</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">With the <Link to="/store/game-book"><Text variant="link">The Quest Game Book</Text></Link>, you can choose from one of eight roles to play when creating your character. Each role has a unique set of abilities that let you do special things. If you were a superhero, these would be your signature powers. </Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">You begin the game with six abilities from the Ability Catalog. At the end of each play session, you can learn one new ability for your role that you can use the next time you play.</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-up" data-sal-duration="500">Some of your abilities are so powerful and taxing that you can only use them occasionally. To use these abilities, you will spend Adventure Points (AP). You begin the game with 10 AP, and earn more while you play and at the end of each session.</Text>
      </Box>
      
      <Image src={forbiddenTreasureImage} alt="An adventurer's hand turns to skeleton in the mist as they open a chest" sx={{ my: 4, width: "100%" }} />
    </Grid>
  )
}

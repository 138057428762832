import React from "react"
import { Box } from "theme-ui"

export default function FullBleedImage({ src }) {
  return (
    <Box
      sx={{ 
        backgroundImage: `url(${src})`, 
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center 33%",
        height: ["50vh", "100vh"],
        borderTop: "1px solid",
        borderBottom: "1px solid",
        my: [6, 8]
      }} 
    />
  )
}

import React from "react"
import { Grid, Box, Text, Image } from "theme-ui"

import spread04Image from "../../images/full-illustrations/spread-04.jpg"

export default function ActionScenes() {
  return (
    <Grid variant="base" gap={[4, 6]} columns={[1, 2, "4fr 3fr"]} sx={{ my: 4, alignItems: "center" }}>
      <Image src={spread04Image} sx={{ border: "1px solid", width: "100%" }} />
      
      <Box>
        <Text as="h2" variant="label" data-sal="slide-left" data-sal-duration="300">Action Scenes</Text>
        <Text as="p" variant="display" data-sal="slide-left" data-sal-duration="300">Move and act during a turn</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-left" data-sal-duration="300">Usually, the action in the game will flow freely while players speak, act, and think about what to do next. But in an action scene, the players take turns. During a turn, your character can move around and do one thing. Your one thing can be a special ability you have or something else you imagine, like helping a friend to their feet, attacking an enemy, or trying to break through a door.</Text>
        <Text as="p" variant="bodyLarge" data-sal="slide-left" data-sal-duration="300">The Guide decides whose turn it is based on what's happening in the story. They'll call on you when it's your turn. When each member of the party has taken a turn, the party has completed a round. Then, the Guide gets a round of turns for all of the creatures they control.</Text>
      </Box>
    </Grid>
  )
}

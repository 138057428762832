import React from "react"
import { Box, Text } from "theme-ui"

export default function Time({ quote, cite }) {
  return (
    <Box
      sx={{
        width: "80%",
        px: [2, 4],
        py: [2, 3],
        bg: `${cite.includes("Guide") ? "transparent" : "text"}`,
        color: `${cite.includes("Guide") ? "text" : "background"}`,
        border: "1px solid",
        borderRadius: `${cite.includes("Guide") ? "32px 32px 32px 0" : "32px 32px 0 32px"}`,
        justifySelf: `${cite.includes("Guide") ? "start" : "end"}`,
      }}
      data-sal="slide-up"
    >
      <Text as="p" variant="label">{cite}</Text>
      <Text as="p" variant="body" sx={{ m: 0 }}>{quote}</Text>
    </Box>
  )
}
